import { PrismicLink, PrismicImage } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";

import { PostDate } from "./PostDate";
import { FirstParagraph } from "./FirstParagraph";
import '../../styles/bloghome.css'
/**
 * Post list item component
 */
export const PostItem = ({ post }) => {
  const title = prismicH.asText(post.data.article_title) || "Untitled";

  return (

    <div className="blog-post">
      <PrismicLink document={post}>
        <PrismicImage
          field={post.data.listing_image}
          alt={`${title} image`}
          width={post.data.listing_image.dimensions.width}
          height={post.data.listing_image.dimensions.height}
        />
        <div>
          <p className="category">{post.data.category?.slug?.replace('-', ' ')}</p>
          <h2>{title}</h2>
          <p className="excerpt">{prismicH.asText(post.data.article_excerpt)}</p>
        </div>

      </PrismicLink>


    </div>
  );
};
