import { PrismicRichText } from "@prismicio/react";

/**
 * Text slice component
 */
export const Text = ({ slice }) => {
  return (
    <div className="post-part single container">

      {
        slice.type == 'image' ?
          (<img src={slice.url} width={slice.dimensions.width} height={slice.dimensions.height} alt={slice.alt}/>)
          : <PrismicRichText field={slice}/>
      }

    </div>
  );
}
