import { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom'
import {
  usePrismicDocumentsByType,
  useSinglePrismicDocument,
} from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";
import dimiFounder from "../images/dimi-founder.png";
import cineSuntemNoi from "../images/cine-suntem-noi.svg";
import { Layout } from "../components/Layout";
import { Header } from "../components/Header";
import '../styles/about.css';

/**
 * Blog homepage component
 */
export const About = () => {
    const [queryParameters] = useSearchParams()
    const [openTab, setOpenTab] = useState(queryParameters.get("page") || null);
    const [viewPort] = useState(window.matchMedia("(min-width: 990px)").matches)
  return (
    <Layout
      seoTitle='Despre Sensus Communis'
      seoDescription=' Sensus Communis este un hub social care atrage oameni pasionați de dezvoltarea personală și educație. Oferim un mediu propice pentru  networking, schimb de idei și creștere personală. Suntem o comunitate unită în jurul valorilor de bun-simț și ne dorim să creăm conexiuni durabile între oameni cu aceleași aspirații.'
      seoImage={cineSuntemNoi}
      seoUrl="/despre-noi"
    >
      <Header
        className="sticky"
        mode="dark"
      />

      <div id="about">
        <div className="about-intro d-none d-lg-flex">
          <img src={cineSuntemNoi} className=" d-block" />
        </div>
        <div className={`piloni section ${(openTab === 'piloni'|| (viewPort && (openTab === 'cine-suntem' || openTab === 'valori' || openTab === 'misiune' || openTab === 'despre-dimi'))) && "open"}`}>
            <div className="acc-head" onClick={() => openTab === 'piloni' ? setOpenTab(null) : setOpenTab('piloni')}><span>PILONII NOSTRI</span></div>
            <div className="acc-content">
              <div>
                <h2>Media</h2>
                <p>Continut, comunitate si informatie care studiaza istoria, evolutia, relevanta, prezenta, necesitatea și modelele de bun-simt din societate.</p>
                <h2>Social</h2>
                <p>
                  1. Programe de susținere a incluziunii sociale a copiilor și adolescentilor si găsește soluții de bun-simt pentru a lupta împreuna, împotriva izolării sociale<br />
                  2. Programe care sa susțină comunitățile hiperlocale: bune vecinatati, antreprenori locali și proiecte comunitare de bun-simț.
                </p>
                <h2>Educatie</h2>
                <p>Platforma de educație pentru copii si tineri care sa faciliteze înțelegerea și aplicarea practica a bunului simt în procesul lor de crestere intelectuala, spirituala si de caracter.</p>
                <h2>Mediu</h2>
                <p>Proiecte de ingrijire a mediului înconjurător prin acțiuni ce au în vedere sustenabilitatea la nivel local și numai. </p>

              </div>
            </div>
        </div>
        <div className={`cine-suntem section ${(openTab === 'cine-suntem' || (viewPort && (openTab === 'valori' || openTab === 'misiune' || openTab === 'despre-dimi'))) && "open"}`}>
            <div className="acc-head" onClick={() => openTab === 'cine-suntem' ? setOpenTab(null) : setOpenTab('cine-suntem')}><span>CINE SUNTEM</span></div>
            <div className="acc-content">
              <div>
                <h2>Sensus Communis este un proiect de comunitate și educație dedicat evoluției și promovării bunului simț și culturii caracterului în societatea noastră.</h2>
                <p>
                  Suntem o organizație non-profit, o mișcare socială și o comunitate care are ca scop cercetarea, încurajarea și promovarea culturii și evoluției conceptului de bun simț modern și cultură a caracterului. Ne dorim să construim un viitor fericit pentru generațiile următoare, bazat pe principiile fundamentale ale bunului simț, sărbătorind mostenirea de valori care derivă din normele acestuia.
                </p>
                <p>
                  Suntem deschiși tuturor celor care caută o comunitate activă și implicată. Această comunitate împărtășește valorile noastre și este în căutarea unei vieți mai bune și mai pline de sens.Indiferent de profesie, vârstă sau context social, Sensus Communis îi invită pe toți cei care cred în puterea educației, dialogului și acțiunii responsabile să se alăture mișcării noastre. Ne dorim ca fiecare să contribuie la construirea unei societăți mai bune și să-și găsească propria voce în acest proces de transformare.
                </p>
              </div>
            </div>
        </div>
        <div className={`valori section ${(openTab === 'valori' || (viewPort && (openTab === 'misiune' || openTab === 'despre-dimi' ))) && "open"}`}>
            <div className="acc-head" onClick={() => openTab === 'valori' ? setOpenTab(null) : setOpenTab('valori')}><span>VALORILE NOASTRE</span></div>
            <div className="acc-content">
              <div>
                <h2 className="color-white">Valorile noastre sunt acțiuni concrete, nu doar cuvinte goale, le trăim și le aplicăm cu seriozitate în fiecare aspect al activității noastre.</h2>
                <div className="color-white">
                  <div className="values">
                    <div className="value">
                      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.2009 4.265L19.1999 4.263L19.1979 4.249C18.4579 2.156 16.4679 0.75 14.2469 0.75C12.3269 0.75 10.6629 1.797 9.74689 3.339C8.83089 1.797 7.16689 0.75 5.24689 0.75C3.02589 0.75 1.03589 2.156 0.295892 4.249L0.293892 4.263L0.292892 4.265C-1.89911 10.917 8.92689 16.907 9.38889 17.158C9.50189 17.22 9.62489 17.25 9.74589 17.25H9.74789C9.86889 17.25 9.99189 17.22 10.1049 17.158C10.5669 16.907 21.3929 10.917 19.2009 4.265ZM9.74689 15.634C7.96089 14.58 0.108892 9.615 1.71689 4.735C2.25089 3.249 3.66589 2.25 5.24689 2.25C7.31389 2.25 8.99689 3.932 8.99689 6C8.99689 6.414 9.33289 6.75 9.74689 6.75C10.1609 6.75 10.4969 6.414 10.4969 6C10.4969 3.932 12.1799 2.25 14.2469 2.25C15.8279 2.25 17.2429 3.248 17.7769 4.734C19.3849 9.614 11.5329 14.58 9.74689 15.634Z" fill="#C63527"/>
                      </svg>
                      Bun-simț autentic
                    </div>
                    <div className="value">
                      <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.201 4.31534L9.88099 0.478344C9.37399 0.210344 8.76399 0.210344 8.25599 0.478344L0.936988 4.31534C0.330988 4.63334 -0.0280124 5.25634 0.00198759 5.94034C0.0299876 6.62434 0.440988 7.21434 1.07299 7.47934L2.31899 8.00234V11.8943C2.31899 12.8773 2.84299 13.7883 3.68599 14.2743C4.83699 14.9343 6.70399 15.7213 9.06899 15.7213C10.151 15.7213 12.294 15.5323 14.482 14.2593C15.306 13.7793 15.819 12.8743 15.819 11.8963V8.00134L17.065 7.47934C17.697 7.21434 18.108 6.62434 18.136 5.94034C18.166 5.25634 17.807 4.63334 17.201 4.31534ZM14.319 11.8963C14.319 12.3423 14.092 12.7503 13.728 12.9623C11.843 14.0583 10.001 14.2213 9.06899 14.2213C7.03399 14.2213 5.42499 13.5433 4.43399 12.9733C4.05399 12.7553 3.81899 12.3423 3.81899 11.8943V8.63134L8.39199 10.5493C8.60699 10.6393 8.83899 10.6843 9.06899 10.6843C9.29899 10.6843 9.53099 10.6393 9.74599 10.5493L14.319 8.63134V11.8963ZM16.485 6.09634L9.16599 9.16534C9.10499 9.19234 9.03299 9.19134 8.97199 9.16634L1.65299 6.09634C1.52599 6.04234 1.50299 5.93534 1.49999 5.87634C1.49799 5.81734 1.51099 5.70834 1.63399 5.64334L8.95399 1.80634C8.98999 1.78734 9.02899 1.77834 9.06899 1.77834C9.10799 1.77834 9.14799 1.78734 9.18399 1.80634L16.504 5.64334C16.627 5.70834 16.64 5.81734 16.638 5.87634C16.635 5.93534 16.612 6.04234 16.485 6.09634Z" fill="#C63527"/>
                      </svg>
                      Învățare și evoluție continuă
                    </div>
                    <div className="value">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.5 9.75C11.119 9.75 13.25 7.619 13.25 5C13.25 2.381 11.119 0.25 8.5 0.25C5.881 0.25 3.75 2.381 3.75 5C3.75 7.619 5.881 9.75 8.5 9.75ZM8.5 1.75C10.292 1.75 11.75 3.208 11.75 5C11.75 6.792 10.292 8.25 8.5 8.25C6.708 8.25 5.25 6.792 5.25 5C5.25 3.208 6.708 1.75 8.5 1.75Z" fill="#C63527"/>
                        <path d="M14.124 4.05094C14.818 4.45194 15.25 5.19894 15.25 5.99994C15.25 7.24094 14.24 8.24994 13 8.24994C12.586 8.24994 12.25 8.58595 12.25 8.99994C12.25 9.41394 12.586 9.74994 13 9.74994C15.067 9.74994 16.75 8.06794 16.75 5.99994C16.75 4.66594 16.032 3.42094 14.876 2.75194C14.515 2.54594 14.06 2.66794 13.851 3.02594C13.644 3.38394 13.766 3.84294 14.124 4.05094Z" fill="#C63527"/>
                        <path d="M4 9.75008C4.414 9.75008 4.75 9.41407 4.75 9.00008C4.75 8.58608 4.414 8.25008 4 8.25008C2.76 8.25008 1.75 7.24108 1.75 6.00008C1.75 5.19908 2.182 4.45208 2.876 4.05108C3.234 3.84308 3.356 3.38408 3.149 3.02608C2.94 2.66708 2.485 2.54408 2.124 2.75208C0.968 3.42108 0.25 4.66608 0.25 6.00008C0.25 8.06808 1.933 9.75008 4 9.75008Z" fill="#C63527"/>
                        <path d="M8.49997 11.25C6.38897 11.25 4.55697 12.681 4.04497 14.728L4.02197 14.817L4.74997 15L5.49997 15.093C5.84497 13.713 7.07797 12.75 8.49997 12.75C9.92197 12.75 11.155 13.713 11.5 15.093C11.585 15.433 11.891 15.661 12.227 15.661C12.287 15.661 12.349 15.653 12.409 15.638C12.812 15.538 13.056 15.13 12.955 14.729C12.443 12.681 10.611 11.25 8.49997 11.25Z" fill="#C63527"/>
                        <path d="M4.24992 12C4.24992 11.586 3.91392 11.25 3.49992 11.25C1.97792 11.25 0.655922 12.282 0.286922 13.759L0.0219218 14.818C-0.0780782 15.22 0.165922 15.627 0.567922 15.728C0.628922 15.743 0.689922 15.75 0.750922 15.75C1.08692 15.75 1.39292 15.522 1.47792 15.182L1.74192 14.123C1.94392 13.314 2.66692 12.75 3.49992 12.75C3.91392 12.75 4.24992 12.414 4.24992 12Z" fill="#C63527"/>
                        <path d="M16.978 14.818L16.713 13.758C16.344 12.281 15.023 11.25 13.5 11.25C13.086 11.25 12.75 11.586 12.75 12C12.75 12.414 13.086 12.75 13.5 12.75C14.333 12.75 15.056 13.314 15.258 14.122L15.523 15.181C15.608 15.522 15.914 15.75 16.249 15.75C16.31 15.75 16.372 15.742 16.432 15.727C16.834 15.627 17.079 15.22 16.978 14.818Z" fill="#C63527"/>
                      </svg>
                      Comunitate și colaborare
                    </div>
                    <div className="value">
                      <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.3661 6.39958C14.6801 2.80158 11.4161 0.130584 7.7671 0.252584C4.0941 0.122584 0.816096 2.80058 0.131096 6.40058C-0.446904 9.43758 0.929096 12.5926 3.5541 14.2516C3.9161 14.4806 4.2461 15.0766 4.2461 15.5016V17.6156C4.2461 18.7946 5.1531 19.7526 6.2681 19.7526H9.2291C10.3431 19.7526 11.2501 18.7946 11.2501 17.6156V15.5016C11.2501 15.0766 11.5801 14.4806 11.9421 14.2516C14.5691 12.5916 15.9451 9.43658 15.3661 6.39958ZM9.2291 18.2526H6.2681C5.9801 18.2526 5.7461 17.9666 5.7461 17.6156V16.7526H9.7031C9.7191 16.7526 9.7341 16.7436 9.7501 16.7426V17.6156C9.7501 17.9666 9.5161 18.2526 9.2291 18.2526ZM11.1401 12.9836C10.4121 13.4446 9.8711 14.3936 9.7851 15.2696C9.7561 15.2656 9.7321 15.2526 9.7031 15.2526H5.7091C5.6171 14.3816 5.0801 13.4416 4.3561 12.9836C2.2461 11.6506 1.1401 9.11758 1.6041 6.68058C2.1441 3.84458 4.6971 1.74958 7.6141 1.74958C7.6651 1.74958 7.7161 1.75058 7.7671 1.75158C10.7421 1.65758 13.3421 3.79558 13.8931 6.68058C14.3571 9.11758 13.2501 11.6506 11.1401 12.9836Z" fill="#C63527"/>
                        <path d="M4.84089 3.88691C4.33989 4.16991 3.64289 4.69091 3.16589 5.56691C2.14989 7.43491 2.97389 9.34791 3.14889 9.71191C3.27889 9.98091 3.54589 10.1369 3.82489 10.1369C3.93489 10.1369 4.04589 10.1129 4.14989 10.0629C4.52389 9.88291 4.67989 9.43491 4.50089 9.06191C4.42689 8.90891 3.80289 7.53491 4.48389 6.28291C4.78989 5.71991 5.24789 5.37991 5.57789 5.19391C5.93889 4.98991 6.06689 4.53291 5.86289 4.17191C5.65889 3.80991 5.19989 3.68391 4.84089 3.88691Z" fill="#C63527"/>
                      </svg>
                      Inspirație și impact
                    </div>
                    <div className="value">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.75 0.25H5.75C2.58 0.25 0 2.83 0 6V14C0 17.17 2.58 19.75 5.75 19.75H13.75C16.92 19.75 19.5 17.17 19.5 14V6C19.5 2.83 16.92 0.25 13.75 0.25ZM1.5 14V6C1.5 3.657 3.407 1.75 5.75 1.75H13.75C16.093 1.75 18 3.657 18 6V10.25H16.719C14.545 10.25 12.616 11.292 11.387 12.897C10.581 13.227 9.884 13.25 9.75 13.25C7.685 13.25 6.48 11.724 6.35 11.55C6.102 11.219 5.632 11.152 5.3 11.4C4.969 11.648 4.901 12.119 5.15 12.45C5.438 12.834 7.014 14.75 9.75 14.75C9.861 14.75 10.095 14.742 10.402 14.701C10.147 15.411 10 16.172 10 16.969V18.25H5.75C3.407 18.25 1.5 16.343 1.5 14ZM13.75 18.25H11.5V16.969C11.5 14.091 13.841 11.75 16.719 11.75H18V14C18 16.343 16.093 18.25 13.75 18.25Z" fill="#C63527"/>
                        <path d="M7.75 8.75C8.164 8.75 8.5 8.414 8.5 8V7C8.5 6.586 8.164 6.25 7.75 6.25C7.336 6.25 7 6.586 7 7V8C7 8.414 7.336 8.75 7.75 8.75Z" fill="#C63527"/>
                        <path d="M11.75 8.75C12.164 8.75 12.5 8.414 12.5 8V7C12.5 6.586 12.164 6.25 11.75 6.25C11.336 6.25 11 6.586 11 7V8C11 8.414 11.336 8.75 11.75 8.75Z" fill="#C63527"/>
                      </svg>
                      Transparență și integritate
                    </div>
                  </div>
                  <div className="text">
                    <p>
                      Sensus Communis este un hub social care atrage oameni pasionați de dezvoltarea personală și educație. Oferim un mediu propice pentru  networking, schimb de idei și creștere personală. Suntem o comunitate unită în jurul valorilor de bun-simț și ne dorim să creăm conexiuni durabile între oameni cu aceleași aspirații.
                    </p>
                  </div>
                  </div>
              </div>
            </div>
        </div>
        <div className={`misiune section ${(openTab === 'misiune' || (viewPort && openTab === 'despre-dimi')) && "open"}`}>
            <div className="acc-head"  onClick={() => openTab === 'misiune' ? setOpenTab(null) : setOpenTab('misiune')}><span>MISIUNE SI VIZIUNE</span></div>
            <div className="acc-content">
              <div>
                <h2 className="color-white"><span className="color-black">Viziunea</span> noastra este să construim o lume în care bunul-simț devine instinctiv, ghidând interacțiunile sociale și evoluția noastră.</h2>

                <h2 className="color-white"><span className="color-black">Misiunea</span> noastra este să aducem o revoluție a bunului simț în lume, învățând oamenii să-și asculte vocea interioară a moralității și să acționeze în conformitate cu valorile etice și principiile universale.</h2>
                <p  className="color-white">
                  <span>
                    Ne angajăm să construim o societate în care fiecare individ este responsabil și conștient de impactul său asupra celorlalți și asupra mediului înconjurător, creând astfel un viitor ideal bazat pe încredere, integritate și respect reciproc.
                  </span>
                </p>
              </div>
            </div>
        </div>
        <div className={`despre-dimi section ${openTab === 'despre-dimi' && "open"}`}>
            <div className="acc-head" onClick={() => openTab === 'despre-dimi' ? setOpenTab(null) : setOpenTab('despre-dimi')}><span>DESPRE DIMI</span></div>
            <div className="acc-content">
              <div>
                <h2>“Bunul simt inseamna sa contribui cat mai mult posibil la usurinta si fericirea celor din jur.”</h2>
                <p className="quote-author">
                  <img src={dimiFounder} className="d-none d-lg-block" alt="Dimi" width="67" height="67" />
                  <span>
                    DIMI - Antreprenor și educator de bun simț modern<br />
                    Fondator Sensus Communis
                  </span>
                </p>

                <p className="color-white">
                  Am privilegiul de a fi un tânăr antreprenor din Slatina și vreau să dau ceva înapoi comunității din care fac parte, și nu numai. Pentru că o inițiativă frumoasă poate atrage și altele, am pornit un ONG dedicat în principal tinerilor care au nevoie de ajutor și ghidaj în viață.<br />
                  Scopul meu este să construiesc o comunitate frumoasă, în care fiecare membru să fie atent la cei din jur și la mediul înconjurător, unde putem găsi sprijin și îndrumare oricând, unde să găsim un sens comun.<br />
                  Sub această umbrelă, vom crea, susține și promova proiecte care se bazează pe bunul-simț, cu scopuri clare, semnificație, valoare și echilibru.<br />
                  Eu cred că educația în bun-simț e esențială pentru o viață în care facem alegeri bune, avem relații pline de satisfacție cu ceilalți și contribuim la o societate mai bună.<br />
                  Promovez conceptul de "bun-simț modern", care include comunicare, respect, comunitate, unitate, încredere, libertate personală și succes.<br />
                  Sub umbrela Sensus Communis, sper că veți găsi acest loc. Sensus Communis devine un proiect de comunitate și educație dedicat evoluției și promovării bunului simț și culturii caracterului în societatea noastră.
                </p>
              </div>
            </div>
        </div>
      </div>
    </Layout>
  );
};
