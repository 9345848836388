import { PostItem } from "./PostItem";

/**
 * Post list component
 */
export const PostList = ({ posts, related }) => {
  return (
    <div className="blog-main">
      {posts.map((post, index) => {
        if(related && index > 3)
          return
        else
          return <PostItem post={post} key={post.id} />
      })}
    </div>
  );
};
