import * as prismic from "@prismicio/client";

// Fill in your repository name
export const repositoryName = "sensuscommunis";
const endpoint = prismic.getRepositoryEndpoint(repositoryName);

export const client = prismic.createClient(endpoint, {
  // If your repo is private, add an access token.
  accessToken: "MC5aYkpZREJFQUFCNEEtZ3lj.77-977-9EVHvv73vv73vv70K77-977-977-977-9VSjvv73vv73vv70GaDfvv70D77-9ST3vv71U77-9EATvv70t",

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  routes: [
    {
      type: "post",
      path: "/blog/:uid",
    },
    {
      type: "blog_home",
      path: "/blog",
    },
  ],
});
