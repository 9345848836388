

/**
 * Full width image component
 */
export const FullWidthBanner = ({ url }) => {

  return (
    <div
      className="full-width-image no-bg single"
      style={{ backgroundImage: `url(${url})`, backgroundPosition: 'top center' }}
    >
      <div className="wrapper">
      </div>
    </div>
  );
};
