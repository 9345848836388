import React, {useEffect} from 'react';
import {usePrismicDocumentsByType} from "@prismicio/react";

const EXTERNAL_DATA_URL = 'https://sensuscommunis.ro/posts';



export const Sitemap = ({res}) => {
  const [blogPosts, blogPostsState] = usePrismicDocumentsByType("post", {"pageSize": 100});
  useEffect(() => {
    if (!blogPostsState.state === "failed") {
      console.warn(
        "Blog Home document was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, [blogPostsState.state]);
  if (blogPosts?.results) {
    return (
      <div>
        <code style={{color: '#FFF'}}>
          <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
            {blogPosts?.results.map(({ uid }) => {
            return `
                      <url>
                          <loc>${`${EXTERNAL_DATA_URL}/${uid}`}</loc>
                      </url>
                  `;
          })
            .join('\n\r')}
          </urlset>
        </code>
      </div>


    )
  } else return (
    <h1>NOthing</h1>
  )
}