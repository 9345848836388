
export const Footer = ({mode, className, hasNewsletter}) => (
  <footer className={`${mode} ${className} ${hasNewsletter && 'borderTop'}`}>
    {
      hasNewsletter && (
        <div className="newsletter-subscription">
          <div className="newsletter-marquee">
            <div className="marquee">
              <div className="marquee__inner">
                <span>newsletter <b>•</b></span><span>newsletter <b>•</b></span><span>newsletter <b>•</b></span>
                <span>newsletter <b>•</b></span><span>newsletter <b>•</b></span><span>newsletter <b>•</b></span>
                <span>newsletter <b>•</b></span><span>newsletter <b>•</b></span><span>newsletter <b>•</b></span>
                <span>newsletter <b>•</b></span><span>newsletter <b>•</b></span><span>newsletter <b>•</b></span>
                <span>newsletter <b>•</b></span><span>newsletter <b>•</b></span><span>newsletter <b>•</b></span>
              </div>
            </div>
          </div>
          <div className="klaviyo-form-Wtsjzh"></div>
        </div>
      )
    }

    <div className="copy">
      © 2023 Toate drepturile rezervate. Sensus Communis
    </div>
    <div className="social">
        <div className="useful-links">
          <a href="/despre-noi">DESPRE NOI</a><span>.</span>&nbsp;
          <a href="mailto:voluntariat@sensuscommunis.ro">SCRIE LA SENSUS COMMUNIS</a><span>.</span>&nbsp;
          <a href="/politica-confidentialitate" className="d-">POLITICA DE CONFIDENȚIALITATE</a>
        </div>
        <div className="media-links">
          <a href="https://www.instagram.com/sensus.communis.ro/" aria-label="Sensus Communis Instagram link">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none" >
              <path d="M14.311 0.0410156H5.11849C2.54458 0.0410156 0.522217 2.06338 0.522217 4.63729V13.8298C0.522217 16.4038 2.54458 18.4261 5.11849 18.4261H14.311C16.885 18.4261 18.9073 16.4038 18.9073 13.8298V4.63729C18.9073 2.06338 16.885 0.0410156 14.311 0.0410156ZM17.0688 13.8298C17.0688 15.3926 15.8738 16.5876 14.311 16.5876H5.11849C3.55576 16.5876 2.36073 15.3926 2.36073 13.8298V4.63729C2.36073 3.07456 3.55576 1.87953 5.11849 1.87953H14.311C15.8738 1.87953 17.0688 3.07456 17.0688 4.63729V13.8298Z" fill="white"/>
              <path d="M9.71481 4.63721C7.14088 4.63721 5.11853 6.65956 5.11853 9.23349C5.11853 11.8074 7.14088 13.8297 9.71481 13.8297C12.2887 13.8297 14.3111 11.8074 14.3111 9.23349C14.3111 6.65956 12.2887 4.63721 9.71481 4.63721ZM9.71481 11.9912C8.15208 11.9912 6.95702 10.7962 6.95702 9.23349C6.95702 7.67076 8.15208 6.47569 9.71481 6.47569C11.2775 6.47569 12.4726 7.67076 12.4726 9.23349C12.4726 10.7962 11.2775 11.9912 9.71481 11.9912Z" fill="white"/>
              <path d="M14.311 3.71802C14.8187 3.71802 15.2303 4.1296 15.2303 4.63728C15.2303 5.14495 14.8187 5.55655 14.311 5.55655C13.8033 5.55655 13.3917 5.14495 13.3917 4.63728C13.3917 4.1296 13.8033 3.71802 14.311 3.71802Z" fill="white"/>
            </svg>
          </a>
          <a href="https://www.linkedin.com/company/sensus-communis/" aria-label="Sensus Communis Linkedin link">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.08598 15.2669H3.59316V7.21864H6.08598V15.2669ZM6.33469 4.65445C6.33469 3.84859 5.6809 3.19451 4.87547 3.19451C4.06697 3.19451 3.41482 3.84859 3.41482 4.65445C3.41482 5.46059 4.06697 6.11467 4.87547 6.11467C5.6809 6.11467 6.33469 5.46059 6.33469 4.65445ZM15.416 10.8391C15.416 8.67858 14.9596 7.07619 12.4357 7.07619C11.223 7.07619 10.4089 7.68269 10.0766 8.31342H10.0742V7.21864H7.65261V15.2669H10.0742V11.2709C10.0742 10.2243 10.3406 9.21055 11.6378 9.21055C12.9173 9.21055 12.9588 10.4073 12.9588 11.3373V15.2669H15.416V10.8391ZM18.621 16.2996V2.33983C18.621 1.16158 17.6626 0.203125 16.4843 0.203125H2.52452C1.34627 0.203125 0.387817 1.16158 0.387817 2.33983V16.2996C0.387817 17.4779 1.34627 18.4363 2.52452 18.4363H16.4843C17.6626 18.4363 18.621 17.4779 18.621 16.2996ZM16.4843 1.62759C16.877 1.62759 17.1966 1.94714 17.1966 2.33983V16.2996C17.1966 16.6923 16.877 17.0119 16.4843 17.0119H2.52452C2.13183 17.0119 1.81229 16.6923 1.81229 16.2996V2.33983C1.81229 1.94714 2.13183 1.62759 2.52452 1.62759H16.4843Z" fill="white"/>
            </svg>
          </a>
          <a href="https://www.tiktok.com/@sensus.communis" aria-label="Sensus Communis TikTok link">
            <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.8278 4.52792C15.7942 4.52792 14.8405 4.1855 14.0746 3.60783C13.1963 2.94563 12.5652 1.97424 12.3424 0.854645C12.2872 0.578017 12.2575 0.292546 12.2546 0H9.30193V8.06819L9.29839 12.4875C9.29839 13.669 8.529 14.6708 7.46246 15.0231C7.15294 15.1254 6.81865 15.1738 6.47056 15.1547C6.02626 15.1303 5.60991 14.9963 5.24802 14.7798C4.47792 14.3192 3.9558 13.4837 3.94165 12.5278C3.91936 11.034 5.12704 9.81604 6.61984 9.81604C6.91451 9.81604 7.19751 9.86415 7.46246 9.95152V7.74628V6.95354C7.183 6.91215 6.89859 6.89058 6.611 6.89058C4.97706 6.89058 3.44888 7.56977 2.35652 8.79337C1.53088 9.71805 1.03564 10.8978 0.959235 12.1348C0.859125 13.7599 1.45377 15.3047 2.60697 16.4445C2.77642 16.6118 2.95435 16.7671 3.14042 16.9104C4.12913 17.6713 5.33788 18.0837 6.611 18.0837C6.89859 18.0837 7.183 18.0625 7.46246 18.0211C8.65175 17.845 9.74906 17.3006 10.615 16.4445C11.6791 15.3928 12.267 13.9966 12.2734 12.5105L12.2582 5.91106C12.7658 6.30265 13.3208 6.62668 13.9165 6.8782C14.843 7.26908 15.8253 7.46718 16.8363 7.46683V5.32278V4.52721L16.8278 4.52792Z" fill="white"/>
            </svg>
          </a>
          <a href="https://www.youtube.com/channel/UCM6axpClLgNfxrS3lZrhYWQ" aria-label="Sensus Communis Youtube link">
            <svg width="25" height="19" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.9174 18.0837H12.911C12.1818 18.0787 5.73606 18.0199 3.90984 17.5261C2.63357 17.1829 1.62607 16.1766 1.28206 14.9001C0.804227 13.1062 0.834633 9.65227 0.837943 9.37649C0.834822 9.10199 0.804038 5.61954 1.2806 3.81082C1.28116 3.80916 1.28149 3.80732 1.28206 3.80566C1.6222 2.54376 2.65239 1.50624 3.90672 1.16223C3.90984 1.16128 3.91315 1.16057 3.91632 1.15963C5.72188 0.685104 12.1803 0.627035 12.911 0.62207H12.924C13.655 0.627035 20.1179 0.68586 21.9273 1.18048C23.2003 1.52265 24.207 2.52773 24.552 3.80254C25.0477 5.61254 25.0018 9.10256 24.9968 9.39715C25.0003 9.68731 25.0293 13.1102 24.5542 14.9132C24.5538 14.915 24.5533 14.9167 24.5529 14.9184C24.2087 16.1948 23.2014 17.2012 21.9237 17.5446C21.922 17.5452 21.9202 17.5456 21.9185 17.5462C20.1131 18.0205 13.6545 18.0786 12.924 18.0837H12.9174ZM3.1053 4.29494C2.68573 5.89144 2.72535 9.32949 2.72573 9.36415V9.38902C2.7132 10.3425 2.75727 13.1037 3.10549 14.4116C3.27435 15.0378 3.7712 15.5339 4.40134 15.7033C5.74879 16.0678 10.9986 16.1825 12.9174 16.1959C14.8411 16.1825 20.0987 16.0709 21.4362 15.721C22.0643 15.5511 22.5595 15.0566 22.7295 14.4296C23.0781 13.1027 23.1218 10.3549 23.109 9.40746C23.109 9.39749 23.109 9.38755 23.1092 9.37758C23.1265 8.41269 23.0924 5.61916 22.7311 4.30085C22.7307 4.29958 22.7304 4.29825 22.7302 4.29697C22.5606 3.66796 22.0636 3.17188 21.4334 3.00245C20.0991 2.63763 14.8407 2.52329 12.9174 2.50986C10.9949 2.52329 5.7425 2.63465 4.40097 2.98419C3.78283 3.15566 3.27473 3.66943 3.1053 4.29494ZM10.5114 13.1755V5.53012L17.1186 9.35289L10.5114 13.1755Z" fill="white"/>
            </svg>
          </a>
        </div>
    </div>
  </footer>
);
