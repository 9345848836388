import { PrismicText } from "@prismicio/react";
import {useEffect, useState} from "react";

import burgerIcon from "../images/burger.svg"
import closeMenuIcon from "../images/close-menu.svg"
import logoMobile from '../images/sensus-communis-logo-short.svg';
import logoDesktop from '../images/sensus-communis-logo.svg'
import logoDesktopWhite from '../images/sensus-communis-logo-white.svg'
import {Footer} from "./Footer";

/**
 * Homepage header component
 */
export const Header = ({ image, headline, description, mode, className }) => {
  const [menuOpened, setMenuOpened] = useState(false);



  const openMenu = () => {
    setMenuOpened(true)
  }
  const closeMenu = () => {
    setMenuOpened(false)
  }
  useEffect(() => {
    algoliasearchNetlify({
      appId: '0HD60RQ9G9',
      apiKey: 'd1d08c72251f1080aeed4aa7580eb5c4',
      siteId: '1dfff0e7-9ce9-40a9-b4f9-e74c582adb15',
      branch: 'main',
      selector: 'div#search',
    });
  }, [])

  return (
    <header className={`header ${className}`}>

      <div className={`menu ${menuOpened && 'open'}`}>
        <div className="header dark">
          <a href="/" className="logo">
            <img src={logoMobile} className="d-md-none" width="70" height="26" alt="Sensus Communis logo" />
            <img src={logoDesktopWhite} className="d-none d-md-block" width="223" height="58" alt="Sensus Communis logo" />
          </a>
          <img src={ closeMenuIcon } className="burger" width="28" height="28" alt="Burger Menu" onClick={() => {closeMenu()}} />
        </div>
        <div className="navigation">
          <div className="col-container">
            <div className="col despre">
              <h2>Despre noi</h2>
              <a href="/despre-noi?page=piloni">PILONII NOSTRI</a>
              <a href="/despre-noi?page=cine-suntem">CINE SUNTEM</a>
              <a href="/despre-noi?page=valori">VALORILE NOASTRE</a>
              <a href="/despre-noi?page=misiune">MISIUNE SI VIZIUNE</a>
              <a href="/despre-noi?page=despre-dimi">DESPRE DIMI</a>
            </div>
            <div className="col bunsimt">
              <h2>Bunul Simt</h2>
              <a href="/blog">
                Articole de bun-simt si cultura a caracterului
              </a>
            </div>
            <div className="col d-none">
              <h2>Sensus Intreaba</h2>
              <a href="/sensus-intreaba/ce-este-bunul-simt">
                Ce este bunul-simt?
              </a>
              <a href="/sensus-intreaba/e-bine-sa-avem-incredere-in-oameni">
                E bine sa avem incredere in oameni ?
              </a>
            </div>
            <div className="col contact">
              <a href="/contact" className="h2">Contact</a>
            </div>
          </div>

        </div>
        <Footer />
      </div>

      <a href="/" className="logo">
        <img src={logoMobile} className="d-md-none" width="70" height="26" alt="Sensus Communis logo" />
        {
          mode === 'dark'
          ? (<img src={logoDesktop} className="d-none d-md-block" width="223" height="58" alt="Sensus Communis logo" />)
          : (<img src={logoDesktopWhite} className="d-none d-md-block" width="223" height="58" alt="Sensus Communis logo" />)
        }
      </a>
      {/* <div className="search" style="display: none !important" id="search"></div> */}
      <img src={ burgerIcon } className="burger" width="28" height="19" alt="Burger Menu" onClick={() => {openMenu()}} />
    </header>
  );

}

