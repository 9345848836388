import {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {usePrismicDocumentByUID, usePrismicDocumentsByType, PrismicImage} from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";
import { PostList } from "../components/PostList";
import { Text } from "../slices/Text";
import { Quote } from "../slices/Quote";
import { FullWidthBanner } from "../components/FullWidthBanner"
import { FullWidthImage } from "../slices/ImageWithCaption/FullWidthImage";
import { Layout } from "../components/Layout";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { BackButton } from "../components/BackButton";
import { NotFound } from "./NotFound";
import {PostDate} from "../components/PostList/PostDate";

/**
 * Blog post page component
 */
export const Post = () => {
  const { uid } = useParams();
  const [scroll, setScroll] = useState(0)
  const [prismicDoc, prismicDocState] = usePrismicDocumentByUID("post", uid);
  const [blogPosts, blogPostsState] = usePrismicDocumentsByType("post", {"pageSize": 3});
  const notFound = prismicDocState.state === "failed" || blogPostsState.state === "failed";

  document.addEventListener("scroll", () => {
    requestAnimationFrame(() => {
      // Calculates the scroll distance
      calculateScrollDistance();
    });
  });
  const calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    const winHeight = document.querySelector('.article-body').offsetHeight + document.querySelector('.outer-container').offsetHeight;
    const docHeight = getDocHeight();
    const totalDocScrollLength = winHeight - window.innerHeight;
    const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)

    setScroll(scrollPostion);
  }
  const getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  }
  useEffect(() => {
    if (prismicDocState.state === "failed" || blogPostsState.state === "failed") {
      console.warn(
        "Blog post document was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, [prismicDocState.state]);

  // Return the page if a document was retrieved from Prismic
  if (prismicDoc && blogPosts) {
    const title = prismicH.asText(prismicDoc.data.article_title) || "Untitled";
    const schema = {
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": title,
      "description" : prismicH.asText(prismicDoc.data.article_excerpt),
      "image": prismicDoc.data.main_article_image.url,
      "datePublished": prismicDoc.first_publication_date,
      "dateCreated": prismicDoc.first_publication_date,
      "dateModified": prismicDoc.last_publication_date,
      "mainEntityOfPage":{
        "@type": "WebPage",
        "@id" : "Sensus Communis"
      },
      "author": {
        "@type": "Person",
        "name": prismicDoc.data?.authorName ? prismicDoc.data?.authorName : 'Sensus Communis',
        "jobTitle": 'Admin'
      },
      "publisher": {
        "@type": "Organization",
        "logo": {
          "@type": "ImageObject",
          "height": 150,
          "url": "https://sensuscommunis.ro/static/media/sensus-communis-logo-white.e8e8849d.svg",
          "width": 800
        },

        "name": "Sensus Communis"
      },
      "url": "https://sensuscommunis.ro/blog/"+prismicDoc.url
    }
    const schemaMarkupBreadcrumb = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement":
        [
          {
            "@type": "ListItem",
            "position": 1,
            "item":
              {
                "@id": "https://sensuscommunis.ro/blog/",
                "name": "Blog"
              }
          },
        ]
    }
    return (
      <Layout
        wrapperClass="main"
        seoTitle={title}
        seoDescription={prismicH.asText(prismicDoc.data.article_excerpt)}
        seoImage={prismicDoc.data.main_article_image.url}
        seoUrl={prismicDoc.url}
        schemaMarkup={schema}
        schemaMarkupBreadcrumb={schemaMarkupBreadcrumb}
      >
        <div className='progress-bar-reading' style={{'width': scroll+'%'}}></div>
        <Header />
        <div className="outer-container">
          <BackButton />
          <div className="category-date">
            <span className="category-name">{prismicDoc.data.category.slug.replace('-', ' ')}</span>
            <span className="separator">•</span>
            <PostDate date={prismicDoc.data.article_date} />
          </div>
          <h1>{title}</h1>
          <p className="article-subtitle">{prismicH.asText(prismicDoc.data.article_subtitle)}</p>
        </div>


        <div className="article-body">
          <FullWidthBanner url={prismicDoc.data.main_article_image.url} />
          <div className="social-container">
            <a href={`https://www.facebook.com/sharer/sharer.php?u=https://sensuscommunis.ro${prismicDoc.url}`} target="_blank" rel="noreferrer" className="social-share">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.4064 0.117065H2.46411C1.2874 0.117065 0.330078 1.07439 0.330078 2.25109V16.1934C0.330078 17.3701 1.2874 18.3274 2.46411 18.3274H16.4064C17.5831 18.3274 18.5405 17.3701 18.5405 16.1934V2.25109C18.5405 1.07439 17.5831 0.117065 16.4064 0.117065ZM17.1178 16.1934C17.1178 16.5856 16.7987 16.9048 16.4064 16.9048H12.3518V11.1073H14.5495L14.9126 8.90215H12.3518V7.37276C12.3518 6.76901 12.815 6.30575 13.4188 6.30575H14.877V4.10058H13.4188C11.6076 4.10058 10.1474 5.56773 10.1474 7.37895V8.90215H8.01258V11.1073H10.1474V16.9048H2.46411C2.07187 16.9048 1.75276 16.5856 1.75276 16.1934V2.25109C1.75276 1.85886 2.07187 1.53975 2.46411 1.53975H16.4064C16.7987 1.53975 17.1178 1.85886 17.1178 2.25109V16.1934Z" fill="black"/>
              </svg>
              SHARE
            </a>
            <a href={`https://twitter.com/intent/tweet?url=https://sensuscommunis.ro${prismicDoc.url}&text=${title}&via=SensusCommunis`} target="_blank" rel="noreferrer"  className="social-share">
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.93163 1.60799H5.3718L16.1472 16.0153H13.707L2.93163 1.60799ZM16.0578 0.458252L10.466 6.49939L5.94791 0.458252H0.636294L7.52802 9.67316L0.592773 17.1652H2.15362L8.22541 10.6058L13.1313 17.1652H18.4429L11.1634 7.43204L17.6187 0.458252H16.0578Z" fill="black"/>
            </svg>

              TWEET
            </a>
            <a href={`https://www.linkedin.com/cws/share?url=${prismicDoc.url}`} target="_blank" rel="noreferrer"  className="social-share d-none">

              Snap
            </a>
          </div>
          {
            prismicDoc.data.body.map((slice) => {
              switch (slice.slice_type) {

                case 'multiline_paragraph':
                  return (
                    <div className="paragraph-wrapper" key={slice.id}>
                      <Text slice={slice.primary.p}/>
                    </div>
                  )
                case 'quote':
                  return (
                    <div className="quote-wrapper" key={slice.id}>
                      <Quote slice={slice}/>
                    </div>
                  )
                case 'image':
                  return (
                    <div className="image-wrapper" key={slice.id}>
                      <FullWidthImage slice={slice}/>
                    </div>
                  )
              }
            })

          }
        </div>
        <div className="articole-similare">
          <h2>Articole Similare</h2>
          <PostList posts={blogPosts?.results} related={true}/>
        </div>

        <Footer hasNewsletter={true}/>
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
