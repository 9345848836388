import {useEffect, useState} from "react";
import {
  usePrismicDocumentsByType,
} from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";

import { PostList } from "../components/PostList";
import { NotFound } from "../pages/NotFound";

/**
 * Blog homepage component
 */
export const ArticleList = () => {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const p = urlParams.get('page');

  const [page, setPage] = useState(parseInt(p) || 1)
  const [blogPosts, blogPostsState] = usePrismicDocumentsByType("post", {"pageSize": 6, "page": page});
  const [blogCategory, blogCategoryState] = usePrismicDocumentsByType("category");

  useEffect(() => {

  })
  const notFound = blogPostsState.state === "failed" || blogCategoryState.state === "failed";

  const updatePage = (pageNo) => {
    const url = new URL(window.location);
    url.searchParams.set('page', pageNo);
    setPage(pageNo)
    window.history.pushState(null, '', url.toString());
  }
  // Return the page if a document was retrieved from Prismic
  if (blogPosts?.results && blogCategory?.results) {

    return (
        <div className="article-list" id="article-list">
          <h2>Ultimele Articole</h2>
          <PostList posts={blogPosts?.results} related={false}/>
          <div className="category-list d-none">
            <h3>Categorii</h3>
            <div className="cat-container">
              {
                blogCategory?.results.map((category) => {
                  return (
                    <div className="cat" style={{backgroundColor: category.data.category_color}}>{prismicH.asText(category.data.category_title)}</div>
                  )
                })
              }
            </div>

          </div>
          {
            blogPosts.total_pages > 1 && (
              <div className="pagination">
                {
                  page > 1 && (
                    <button onClick={() => updatePage(page-1)} className="prev" >
                      <svg xmlns="http://www.w3.org/2000/svg"  width="11" height="19" viewBox="0 0 11 19" fill="none">
                        <path d="M0.13494 9.37281C0.13494 9.15875 0.216755 8.94449 0.380174 8.78107L8.74994 0.411302C9.07699 0.0842535 9.60659 0.0842535 9.93342 0.411302C10.2603 0.738351 10.2605 1.26795 9.93342 1.59479L2.1554 9.37281L9.93342 17.1508C10.2605 17.4779 10.2605 18.0075 9.93342 18.3343C9.60638 18.6612 9.07678 18.6614 8.74994 18.3343L0.380174 9.96455C0.216755 9.80113 0.13494 9.58687 0.13494 9.37281Z" fill="black"/>
                      </svg>
                    </button>
                  )
                }

                {
                  Array.from(Array(blogPosts.total_pages), (e, i) => {
                    return <div className={`page ${page == (i+1) && "active"}`} key={i} onClick={() => updatePage(i+1)}>{i+1}</div>
                  })
                }
                {
                  page < blogPosts.total_pages && (
                    <button onClick={() => updatePage(page+1)} className="next">
                      <svg xmlns="http://www.w3.org/2000/svg"  width="11" height="19" viewBox="0 0 11 19" fill="none">
                        <path d="M10.2166 9.63793C10.2166 9.85199 10.1348 10.0663 9.97139 10.2297L1.60162 18.5994C1.27457 18.9265 0.744977 18.9265 0.418138 18.5994C0.0912987 18.2724 0.0910894 17.7428 0.418138 17.416L8.19616 9.63793L0.418138 1.85991C0.0910894 1.53286 0.0910894 1.00327 0.418138 0.676426C0.745187 0.349585 1.27478 0.349375 1.60162 0.676426L9.97139 9.04619C10.1348 9.20961 10.2166 9.42388 10.2166 9.63793Z" fill="black"/>
                      </svg>
                    </button>
                  )
                }

              </div>
            )
          }
        </div>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
