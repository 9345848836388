import {useEffect, useState} from "react";
import {
  PrismicRichText,
  usePrismicDocumentByUID,
} from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";
import { Layout } from "../components/Layout";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import '../styles/sensus-intreaba.css';
import { NotFound } from "./NotFound";
import {useParams} from "react-router-dom";
import Flickity from 'react-flickity-component'

/**
 * Blog homepage component
 */
export const Intrebari = () => {
  const { uid } = useParams();
  const [openedCard, setOpenedCard] = useState(null)
  const [prismicDoc, prismicDocState] = usePrismicDocumentByUID("intrebari", uid);

  const notFound =
    prismicDocState.state === "failed";

  useEffect(() => {
    if (!prismicDocState.state === "failed") {
      console.warn(
        "Blog Home document was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, [prismicDocState.state]);

  const showText = (val) => {
    setOpenedCard(openedCard === val ? null : val)
  }
  const flickityOptions = {
    initialIndex: 0,
    prevNextButtons: false,
    pageDots: false,
    cellAlign: 'left'
  }
  // Return the page if a document was retrieved from Prismic
  if (prismicDoc) {
    const title = prismicH.asText(prismicDoc.data.intrebare) || "Untitled";

    return (
      <Layout seoTitle={title}>
        <Header
          className="sticky"
          mode="dark"
        />
        <div className="sensus-intreaba">
          <Flickity
            className={'carousel d-none'} // default ''
            elementType={'div'} // default 'div'
            options={flickityOptions} // takes flickity options {}
            disableImagesLoaded={false} // default false
            reloadOnUpdate // default false
            static // default false
          >
            {
              prismicDoc.data.responses.map((response) => {
                return (
                  <div className={`cell`}>
                    <div className="text-container" style={{backgroundColor: response.background_color, color: response.text_color, borderColor: response.text_color}}>
                      <PrismicRichText field={response.response_text}/>
                    </div>
                    <img src={response.response_author.url} width={response.response_author.dimensions.width} height={response.response_author.dimensions.height}/>
                  </div>
                )
              })
            }

          </Flickity>
          <h1>{title}</h1>
        </div>

        <Footer mode="light" />
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
