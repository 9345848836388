import { PrismicText } from "@prismicio/react";

/**
 * Quote slice component
 */
export const Quote = ({ slice }) => {
  return (
    <div className="post-part single container">
      <blockquote className="block-quotation">
        <PrismicText field={slice.primary.quote_text} />
      </blockquote>
    </div>
  );
}
