import { Helmet } from "react-helmet";

/**
 * Default layout wrapper component
 */
export const Layout = ({ wrapperClass, children, seoTitle, seoDescription, seoUrl, seoImage, schemaMarkup, schemaMarkupBreadcrumb }) => (
  <div className={wrapperClass}>
    <Helmet>
      <title>{seoTitle}</title>
      <meta property="og:url" content={`https://sensuscommunis.ro${seoUrl}`} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:image" content={seoImage} />
      <meta property="og:site_name" content="Sensus Communis" />
      <meta name="description" content={seoDescription} />
      {schemaMarkup &&
        <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
      }
      {schemaMarkupBreadcrumb &&
        <script type="application/ld+json">{JSON.stringify(schemaMarkupBreadcrumb)}</script>
      }
    </Helmet>
    {children}
  </div>
);
