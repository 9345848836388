import { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom'
import textSvg from "../images/contact-mail-link.svg";
import nose from "../images/nose.svg";
import { Layout } from "../components/Layout";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import '../styles/contact.css';

/**
 * Blog homepage component
 */
export const Contact = () => {
  const [queryParameters] = useSearchParams()
  const [openTab, setOpenTab] = useState(queryParameters.get("page") || 'piloni');
  const [viewPort] = useState(window.matchMedia("(min-width: 990px)").matches)

  useEffect(() => {
    const pupils = document.querySelectorAll(".pupil");
    window.addEventListener("mousemove", (e) => {
      var rect = pupils[0].getBoundingClientRect();
      pupils.forEach((pupil) => {
        // get x and y postion of cursor

        var x = (e.pageX - rect.left) / 10 + "px";
        var y = (e.pageY - rect.top) / 5 + "px";
        pupil.style.transform = "translate3d(" + x + "," + y + ", 0px)";
      });
    });
  }, [])


  return (
    <Layout
      seoTitle='Contact Sensus Communis'
      seoDescription='Dacă îți place activitatea Sensus Communis, dacă și tu vrei să fii alături de noi în comunitatea noastră de #bunsimt sau dacă ai pur și simplu o idee pentru care noi suntem oamenii potriviți, completează formularul de mai sus și hai să ne cunoaștem!'
      seoUrl='/contact'
      seoImage={textSvg}
    >
      <Header
        className="sticky"
        mode="light"
      />

      <div id="contact">
        <div className="contact-container">
          <div className="eyes-container">
            <img src={nose} alt="Nose" className="nose" />
            <div className="eyes">
              <div className="eye">
                <div className="pupil"></div>
              </div>
              <div className="eye">
                <div className="pupil"></div>
              </div>
            </div>
            <a href="mailto:voluntariat@sensuscommunis.ro" target="_blank">
              <img src={textSvg} width="314" height="100" alt="voluntariat@sensuscommunis.ro" />
            </a>
          </div>
          {/*<h1>*/}
          {/*  Hello  :)<br />*/}
          {/*  Hai să ne cunoaștem!*/}
          {/*</h1>*/}
          {/*<div className="form-container">*/}
          {/*  <input type="text" placeholder="Nume Prenume" />*/}
          {/*  <input type="text" placeholder="Adresa ta de email" />*/}
          {/*  <textarea placeholder="Mesajul tau">*/}

          {/*</textarea>*/}
          <div className="form-container">
            <p>
              Dacă îți place activitatea Sensus Communis, dacă și tu vrei să fii alături de noi în comunitatea noastră de #bunsimt sau dacă ai pur și simplu o idee pentru care noi suntem oamenii potriviți, trimite-ne un email pe adresa de mai sus și hai să ne cunoaștem!
            </p>
          </div>

        </div>
      </div>
      <Footer className="" />
    </Layout>
  );
};
