import {useEffect, useState} from "react";
import {
  usePrismicDocumentByUID,
  usePrismicDocumentsByType,
  useSinglePrismicDocument,
  PrismicLink, PrismicImage
} from "@prismicio/react";
import algoliasearch from "algoliasearch";
import * as prismicH from "@prismicio/helpers";
import blogHomeImage from '../images/blog-home-image.png';
import blogHomeImageMobile from '../images/blog-home-image_mobile.png';
import blogHomeImageMobileWebp from '../images/blog-home-image_mobile.webp';
import blogHomeImageWebp from '../images/blog-home-image.webp';
import { Layout } from "../components/Layout";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { NotFound } from "./NotFound";
import dimiFounder from "../images/dimi-founder.png";
import dimiFounderWebp from "../images/dimi-founder.webp";
import {PostItem} from "../components/PostList/PostItem";
import {ArticleList} from "../components/ArticleList";
import '../styles/bloghome.css'

/**
 * Blog homepage component
 */
export const Blog = () => {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const p = urlParams.get('page')
  const [page, setPage] = useState(parseInt(p) || 1)
  const [homeDoc, homeDocState] = useSinglePrismicDocument("blog_home");
  const [blogPosts, blogPostsState] = usePrismicDocumentsByType("post", {"pageSize": 6});
  const [blogCategory, blogCategoryState] = usePrismicDocumentsByType("category");
  const [mainArticle, mainArticleState] = usePrismicDocumentByUID('post', 'curatenia-de-toamna-din-strehareti')
  const [secondArticle, secondArticleState] = usePrismicDocumentByUID('post', 'de-ce-este-educatia-pentru-bun-simt-esentiala')

  const client = algoliasearch('0HD60RQ9G9', 'ace347a3ca1a4c9b3c002a23db485f8b')
  const index = client.initIndex('netlify_1dfff0e7-9ce9-40a9-b4f9-e74c582adb15_main_all')

  const notFound =
    homeDocState.state === "failed" || blogPostsState.state === "failed" || blogCategoryState.state === "failed";

  useEffect(() => {
    if (!homeDocState.state === "failed" || !mainArticleState.state === "failed") {
      console.warn(
        "Blog Home document was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, [homeDocState.state]);

  // Return the page if a document was retrieved from Prismic
  if (homeDoc && blogPosts?.results && blogCategory?.results && mainArticle && secondArticle) {
    const title = prismicH.asText(homeDoc.data.headline);
    index.saveObjects(blogPosts.results, {'autoGenerateObjectIDIfNotExist': true})
    return (
      <Layout
        seoTitle="Sensus Communis Bun Simt"
        seoDescription='Sensus Communis este un proiect de comunitate și educație dedicat evoluției și promovării bunului simț și culturii caracterului în societatea noastră.'
        seoImage={blogHomeImage}
        seoUrl="/blog"
      >
        <Header
          image={homeDoc.data.image}
          headline={homeDoc.data.headline}
          description={homeDoc.data.description}
        />
        <div className="top-blog">
          <div className="main-art">
            <PostItem post={mainArticle} key={mainArticle.id} />
          </div>
          <div className="second-art">
            <PostItem post={secondArticle} />
            <div className="founder-dimi">
              <picture className="">
                <source srcSet={dimiFounderWebp} type="image/webp" />
                <source srcSet={dimiFounder} type="image/png" />
                <img src={dimiFounder} width="92" height="92" alt="Dimi founder"  />
              </picture>
              <p>
                DIMI, Fondator Sensus Communis, va susține și promova proiecte care se bazează pe bunul-simț, cu scopuri clare, semnificație, valoare și echilibru.<br /><br />
                <a href="/despre-noi?page=despre-dimi">AFLA MAI MULTE</a>
              </p>
            </div>
          </div>
          <div className="teaser-articles">
            {
              blogPosts?.results.map((post, index) => {
                if(index > 3){
                  return
                } else {
                  return (
                    <div className="teaser-post" key={post.id}>
                      <PrismicLink document={post}>
                        <div>
                          <p className="category">{post.data.category?.slug?.replace('-', ' ')}</p>
                          <h2>{prismicH.asText(post.data.article_title)}</h2>
                        </div>
                        <PrismicImage
                          field={post.data.listing_image}
                          alt={`${title} image`}
                          width={post.data.listing_image.dimensions.width}
                          height={post.data.listing_image.dimensions.height}
                        />
                      </PrismicLink>
                    </div>
                  )
                }
              })
            }
            <a href="javascript: document.getElementById('article-list').scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth' })" >TOATE ARTICOLELE</a>
          </div>
        </div>
        <div className="banner-liquid">
          <div className="page-width">
            <h2>Sensus Communis este un proiect de comunitate și educație dedicat evoluției și promovării bunului simț și culturii caracterului în societatea noastră.</h2>
          </div>
          <picture className="w-100">
            <source media="(max-width: 768px)"
                    sizes="300px"
                    srcSet={`${blogHomeImageMobileWebp}, ${blogHomeImageMobile}`}
                    type="image/jpg"/>
            <source media="(min-width: 768px)"
                    sizes="992px"
                    srcSet={`${blogHomeImageWebp}, ${blogHomeImage}`}
                    type="image/jpg"/>
            <img src={blogHomeImage} width="1480" height="736" alt="People laughing"/>
          </picture>

        </div>

        <div className="newsletter-subscription white">
          <div className="newsletter-marquee">
            <div className="marquee">
              <div className="marquee__inner">
                <span>newsletter <b>•</b></span><span>newsletter <b>•</b></span><span>newsletter <b>•</b></span>
                <span>newsletter <b>•</b></span><span>newsletter <b>•</b></span><span>newsletter <b>•</b></span>
                <span>newsletter <b>•</b></span><span>newsletter <b>•</b></span><span>newsletter <b>•</b></span>
                <span>newsletter <b>•</b></span><span>newsletter <b>•</b></span><span>newsletter <b>•</b></span>
                <span>newsletter <b>•</b></span><span>newsletter <b>•</b></span><span>newsletter <b>•</b></span>
              </div>
            </div>
          </div>
          <div className="klaviyo-form-Wtsjzh"></div>
        </div>
        <ArticleList />
        <Footer mode="light" />
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
