import { Layout } from "../components/Layout";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

import curateniaDeToamna from "../images/home-main-article-image.png";
import curateniaDeToamnaWebp from "../images/home-main-article-image.webp";
import bunSimtDesktop from '../images/bun-simt.png';
import bunSimtMobile from '../images/bun-simt-mobile.png';
import despreDimiHome from '../images/despre-dimi-home.png';
import despreDimiHomeWebp from '../images/despre-dimi-home.webp';
import aiInArt from '../images/home-s1-support.png'
import aiInArtWebp from '../images/home-s1-support.webp'
import '../styles/home.css';

/**
 * Blog homepage component
 */
export const Home = () => {

  return (
    <Layout
      seoTitle='Sensus Communis'
      seoDescription='Organizație de bun simț modern și cultură a caracterului'
      seoUrl='/'
      seoImage={bunSimtDesktop}
    >
      <Header
        className="sticky"
        mode="dark"
      />

      <div className="home-s1">
        <a href="/blog" className="bun-simt">
          <svg width="455" height="68" viewBox="0 0 455 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_405_9)">
            <path d="M33.7103 66.2777L30.2457 51.8166H12.8131L9.3485 66.2777H0L16.8552 0.485001H26.3928L43.248 66.2777H33.7103ZM21.4846 14.085L14.5554 43.6803H28.4238L21.4846 14.085Z" fill="black"/>
            <path d="M82.673 66.2777L67.9384 38.8897H62.9306V66.2777H53.8708V1.72227H71.8809C83.4396 1.72227 91.5336 8.33421 91.5336 19.8259C91.5336 29.1103 84.9827 35.5342 76.8887 37.1575L93.1664 66.2678H82.663L82.673 66.2777ZM70.6364 9.86842H62.9306V31.1296H67.8488C76.0424 31.1296 82.2947 28.0611 82.2947 19.8259C82.2947 13.4119 77.3864 9.85852 70.6364 9.85852V9.86842Z" fill="black"/>
            <path d="M117.956 9.9674V66.2777H108.907V9.9674H94.3611V1.72227H132.502V9.9674H117.956Z" fill="black"/>
            <path d="M142.248 66.2777V1.72227H151.308V66.2777H142.248Z" fill="black"/>
            <path d="M191.012 66.8518C171.548 66.8518 163.464 50.1834 163.464 33.1389C163.464 14.5601 173.002 1.14818 192.356 1.14818C193.71 1.14818 194.964 1.14818 196.976 1.43522L196.398 9.75953C195.343 9.67045 194.178 9.67045 193.411 9.67045C178.478 9.67045 172.982 20.5881 172.982 33.03C172.982 45.4719 178.279 58.3196 192.635 58.3196C193.69 58.3196 194.656 58.2207 195.721 58.1217L196.199 66.644C194.467 66.733 192.734 66.8419 191.002 66.8419L191.012 66.8518Z" fill="black"/>
            <path d="M221.467 68C205.089 68 200.858 48.5601 200.858 34C200.858 19.4399 205.378 0 221.467 0C237.555 0 242.075 19.1627 242.075 34C242.075 48.8373 237.545 68 221.467 68ZM221.467 8.24512C211.74 8.24512 210.097 23.0824 210.097 34C210.097 44.9176 211.83 59.7648 221.467 59.7648C231.104 59.7648 232.836 44.8186 232.836 34C232.836 22.2213 231.104 8.24512 221.467 8.24512Z" fill="black"/>
            <path d="M254.251 66.2777V1.72227H263.311V58.0425H286.339V66.2777H254.261H254.251Z" fill="black"/>
            <path d="M296.086 66.2777V1.72227H328.84V9.9674H305.145V26.6358H328.84V34.485H305.145V58.0425H328.84V66.2777H296.086Z" fill="black"/>
            <path d="M384.642 66.2777H368.554V1.72227H384.642C402.274 1.72227 410.079 15.9953 410.079 33.2379C410.079 51.7176 400.731 66.2777 384.642 66.2777ZM384.642 9.86842H377.614V58.1415H384.642C395.723 58.1415 400.731 46.6498 400.731 33.3368C400.731 20.0239 394.857 9.86842 384.642 9.86842Z" fill="black"/>
            <path d="M422.245 66.2777V1.72227H455V9.9674H431.305V26.6358H455V34.485H431.305V58.0425H455V66.2777H422.245Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_405_9">
            <rect width="455" height="68" fill="white"/>
            </clipPath>
            </defs>
          </svg>

          <svg width="274" height="260" viewBox="0 0 274 260" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_29_9" style={{maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="0" y="0" width="274" height="260">
              <path d="M273.562 0.975586H0.438004V259.541H273.562V0.975586Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_29_9)">
              <path d="M68.354 125.53H3.097V3.50977H68.354C82.382 3.50977 93.867 13.1098 93.867 34.8298C93.867 45.1488 90.207 53.4768 85.735 55.4668C92.647 56.9148 99.762 67.0528 99.762 84.0668C99.762 113.033 85.634 125.524 68.354 125.524M63.068 32.2948H26.577V46.9588H63.17C67.235 46.9588 69.675 44.2438 69.675 39.3588C69.675 34.2888 67.134 32.2968 63.067 32.2968M63.78 74.8408H26.58V96.7468H64.289C72.318 96.7468 75.571 93.1258 75.571 84.9788C75.571 77.9188 72.42 74.8408 63.78 74.8408Z" fill="black"/>
              <path d="M163.5 91.4948C163.5 111.951 156.181 128.788 135.852 128.788C116.538 128.788 108.305 111.951 108.305 91.4948V3.50977H129.447V87.8738C129.447 94.7528 131.48 100.365 135.847 100.365C140.623 100.365 142.352 95.1148 142.352 87.8738V3.50977H163.494L163.5 91.4948Z" fill="black"/>
              <path d="M271.656 128.064H268.709L197.756 62.5286V125.529H174.276V0.975586H177.224L248.172 65.9676V3.50959H271.656V128.064Z" fill="black"/>
              <path d="M9.601 257.755C6.76541 257.752 3.93284 257.57 1.12 257.211L1.899 227.085C5.19782 228.215 8.65176 228.828 12.138 228.9C21.009 228.9 23.638 225.271 23.638 220.734C23.638 215.834 21.104 212.748 14.572 206.034C6.091 197.867 0.438004 187.705 0.438004 170.283C0.438004 145.421 13.305 132.899 31.338 132.899C34.8421 132.918 38.3358 133.282 41.768 133.988L40.989 163.751C37.565 162.59 33.9785 161.978 30.363 161.938C22.955 161.938 20.81 164.478 20.81 168.108C20.81 172.645 23.929 175.185 29.389 180.629C38.065 188.615 44.01 198.051 44.01 217.106C44.01 246.324 28.61 257.756 9.601 257.756" fill="black"/>
              <path d="M70.235 134.17H51.555V256.486H70.235V134.17Z" fill="black"/>
              <path d="M159.593 256.486V198.232L131.663 231.08H129.149L101.398 198.413V256.485H80.652V131.63H83.252L130.49 188.069L177.728 131.63H180.328V256.486H159.593Z" fill="black"/>
              <path d="M240.422 165.928V233.159H219.768V165.928H186.719V134.17H273.562V165.928H240.422Z" fill="black"/>
              <path d="M213.957 259.54L219.779 236.324H240.372L229.656 259.54H213.957Z" fill="black"/>
            </g>
          </svg>
          <p>TOATE ARTICOLELE</p>
          <div className="marquee">
            <div className="marquee__inner">
              <span>Organizație de bun simț modern și cultură a caracterului.</span>
              <span>Organizație de bun simț modern și cultură a caracterului.</span>
              <span>Organizație de bun simț modern și cultură a caracterului.</span>
              <span>Organizație de bun simț modern și cultură a caracterului.</span>
              <span>Organizație de bun simț modern și cultură a caracterului.</span>
            </div>
          </div>
        </a>
        <div className="support-s1">
          <a href="/blog/curatenia-de-toamna-din-strehareti" className="support-article">
            <picture className="">
              <source srcSet={curateniaDeToamnaWebp} type="image/webp" />
              <source srcSet={curateniaDeToamna} type="image/png" />
              <img src={curateniaDeToamna} width="400" height="402" alt="Articol Curatenia de toamna" />
            </picture>

            <div>Curățenia de Toamnă <br />din Strehareți</div>
          </a>
          <div className="support-empty"></div>
          <div  className="support-menu">
            <a href="/despre-noi?page=piloni" className="tag white">Pilonii noștri</a>
            <a href="/despre-noi?page=cine-suntem" className="tag yellow">Cine suntem</a>
            <a href="/despre-noi?page=valori" className="tag red">Valorile noastre</a>
            <a href="/despre-noi?page=misiune" className="tag green">Misiune si viziune</a>
            <a href="/despre-noi?page=despre-dimi" className="tag blue">Despre Dimi</a>
            <a href="/despre-noi" className="about">Despre noi</a>
          </div>
          <a href="/blog/impactul-ai-in-arta" className="support-image">
            <picture className="">
              <source srcSet={aiInArtWebp} type="image/webp" />
              <source srcSet={aiInArt} type="image/png" />
              <img src={aiInArt} width="458" height="458" alt="Picture of Dimi"  />
            </picture>
            <div>Impactul <br />AI în Artă</div>
          </a>
          <a href="/despre-noi?page=despre-dimi" className="despre-dimi-home d-flex d-lg-none">
            <picture className="">
              <source srcSet={despreDimiHomeWebp} type="image/webp" />
              <source srcSet={despreDimiHome} type="image/png" />
              <img src={despreDimiHome} width="372" height="368" alt="Picture of Dimi"  />
            </picture>

            <span>DESPRE DIMI</span>
          </a>
        </div>
      </div>
      <div className="home-s2">
        <picture>
          <source media="(min-width: 768px)" srcSet={bunSimtDesktop} />
          <source media="(max-width: 767px)" srcSet={bunSimtMobile} />
          <img src={bunSimtMobile} alt="Bun simt" width="1400" height="764" />
        </picture>
        <div className="sensus-intreaba-link">
          <span className="headline">SENSUS INTREABA</span>
          <span className="title">E bine sa avem<br /> incredere <br />in oameni?</span>
        </div>
        <a href='/despre-noi?page=despre-dimi' className="despre-link">
          <div>
            <picture className="">
              <source srcSet={despreDimiHomeWebp} type="image/webp" />
              <source srcSet={despreDimiHome} type="image/png" />
              <img src={despreDimiHome} width="372" height="368" alt="Picture of Dimi"  />
            </picture>
            <span>DESPRE DIMI</span>
          </div>
        </a>
      </div>
      <Footer hasNewsletter={true} />
    </Layout>
  );
};
